@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: url('HelveticaNeue-Heavy.ttf') format('ttf'), url('HelveticaNeue-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: url('HelveticaNeue-Regular.ttf') format('ttf'), url('HelveticaNeue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: url('HelveticaNeue-Bold.ttf') format('ttf'), url('HelveticaNeue-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
